import { actionTypes } from "./actions";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  successSnackBarOpen: false,
  errorSnackBarOpen: false,
  // 
  successSnackBarMessage: '',
  errorSnackBarMessage: '',
};

function AlertReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    case actionTypes.SUCCESS_SNACKBAR:
      return {
        ...state,
        ...{
          successSnackBarOpen: true,
          successSnackBarMessage: action.payload
        },
      };


    case actionTypes.ERROR_SNACKBAR:
      return {
        ...state,
        ...{
          errorSnackBarOpen: true,
          errorSnackBarMessage: action.payload
        },
      };

    case actionTypes.CLEAR_SNACKBAR:
      return {
        ...state,
        ...{
          successSnackBarOpen: false,
          successSnackBarMessage: "",
          errorSnackBarOpen: false,
          errorSnackBarMessage: "",
        },
      };




    default:
      return state;
  }
}

export default AlertReducer;
