import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { clearAlert } from '../../stores/Alerts/actions'
export default function SuccessSnackbar() {
  const dispatch = useDispatch()
  const { successSnackBarMessage, successSnackBarOpen } = useSelector(
    (state) => state.AlertReducer,
  )

  function handleClose() {
    dispatch(clearAlert('success'))
  }

  const notify = () =>
    toast.success(successSnackBarMessage, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: handleClose('success'),
    })

  const memoizedCallback = useCallback(() => {
    notify()
  }, [successSnackBarOpen])

  useEffect(() => {
    if (successSnackBarOpen) {
      memoizedCallback()
    }
  }, [successSnackBarOpen])

  return <></>
}
