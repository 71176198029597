import { combineReducers } from "redux";
import AlertReducer from "./Alerts/reducer";
import AuthReducer from "./Auth/reducer";

const rootReducer = combineReducers({
  AuthReducer,
  AlertReducer,
});

export default rootReducer;
