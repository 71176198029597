import { Pause } from "@material-ui/icons";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../utils/api";
import { errorAlert, successAlert } from "../Alerts/actions";
import {
  checkAuthFailure,
  checkAuthSuccess,
  fetchDataSuccess,
  fetchDataFailure,
  actionTypes,
  submitTicketSuccess,
  submitTicketFailure,
} from "./actions";
export function* checkAuthSaga({ payload }) {
  if (payload?.token) {
    try {
      const response = yield call(
        api(payload?.token).post,
        payload?.userType === "Customer"
          ? `/api/v1/customer/me`
          : `/api/v1/dealer/me`
      );
      if (response && response?.code === 200) {
        yield put(
          checkAuthSuccess({
            user: response?.data,
            token: payload?.token,
          })
        );
      }
    } catch (e) {
      yield put(errorAlert("Token is invalid or expired!"));
      yield put(checkAuthFailure());
      payload?.router && payload.router.push("/");
    }
  }
}

export function* fetchDataSaga({ payload }) {
  let token = process.env.NEXT_PUBLIC_TOKEN;
  try {
    const response = yield call(
      api(token).post,
      `/get-delivery-tracking-by-tracking-id`,
      payload
    );
    yield put(fetchDataSuccess(response?.data));
  } catch (e) {
    yield put(fetchDataFailure());
  }
}

export function* submitTicketSaga({ payload, onSubmitSuccess }) {
  let token = process.env.NEXT_PUBLIC_TOKEN;
  try {
    const response = yield call(
      api(token).post,
      `/delivery-ticket-enquiry`,
      payload
    );
    yield put(submitTicketSuccess(response));
    yield put(
      successAlert(
        "Form Submitted Successfully. Our team will get back to you shortly!"
      )
    );
    typeof onSubmitSuccess === "function" && onSubmitSuccess();
  } catch (e) {
    yield put(submitTicketFailure());
    yield put(errorAlert("Something went wrong!"));
  }
}

export function* checkAuthFlow() {
  yield takeLatest(actionTypes.CHECK_AUTH, checkAuthSaga);
}

export function* fetchDataFlow() {
  yield takeLatest(actionTypes.FETCH_DATA, fetchDataSaga);
}
export function* submitTicketFlow() {
  yield takeLatest(actionTypes.SUBMIT_TICKET, submitTicketSaga);
}

function* AuthSaga() {
  yield all([checkAuthFlow(), fetchDataFlow(), submitTicketFlow()]);
}

export default AuthSaga;
