import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { persistStore, persistReducer } from "redux-persist";
import storage from "./storage";

// Store
import rootReducer from "./reducer";
import rootSaga from "./saga.js";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// Persist config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["AuthReducer"],
};

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor };