export const actionTypes = {
  // CHECK AUTH
  CHECK_AUTH: "CHECK_AUTH",
  CHECK_AUTH_SUCCESS: "CHECK_AUTH_SUCCESS",
  CHECK_AUTH_FAILURE: "CHECK_AUTH_FAILURE",
  //
  FETCH_DATA: "FETCH_DATA",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
  //
  SET_FORM_VALUES: "SET_FORM_VALUES",
  //
  SUBMIT_TICKET: "SUBMIT_TICKET",
  SUBMIT_TICKET_SUCCESS: "SUBMIT_TICKET_SUCCESS",
  SUBMIT_TICKET_FAILURE: "SUBMIT_TICKET_FAILURE",
};

export function checkAuth(payload) {
  return {
    type: actionTypes.CHECK_AUTH,
    payload,
  };
}
export function checkAuthSuccess(payload) {
  return {
    type: actionTypes.CHECK_AUTH_SUCCESS,
    payload,
  };
}
export function checkAuthFailure(payload) {
  return {
    type: actionTypes.CHECK_AUTH_FAILURE,
    payload,
  };
}

export function fetchData(payload) {
  return {
    type: actionTypes.FETCH_DATA,
    payload,
  };
}
export function fetchDataSuccess(payload) {
  return {
    type: actionTypes.FETCH_DATA_SUCCESS,
    payload,
  };
}
export function fetchDataFailure(payload) {
  return {
    type: actionTypes.FETCH_DATA_FAILURE,
    payload,
  };
}

export function setFormValues(payload) {
  return {
    type: actionTypes.SET_FORM_VALUES,
    payload,
  };
}

export function submitTicket(payload, onSubmitSuccess) {
  return {
    type: actionTypes.SUBMIT_TICKET,
    payload,
    onSubmitSuccess,
  };
}
export function submitTicketSuccess(payload) {
  return {
    type: actionTypes.SUBMIT_TICKET_SUCCESS,
    payload,
  };
}
export function submitTicketFailure(payload) {
  return {
    type: actionTypes.SUBMIT_TICKET_FAILURE,
    payload,
  };
}
