import { actionTypes } from "./actions";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  isFetchingData: false,
  isDataFetched: false,
  trackingData: {},
  formValues: {
    tracking_id: "",
    postcode: "",
  },
  isSubmitting: false,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    case actionTypes.CHECK_AUTH:
      return {
        ...state,
        ...{
          isAuthenticating: true,
          isPageAuthenticated: false,
          isAuthenticated: false,
          userInfo: {},
          token: "",
        },
      };
    case actionTypes.CHECK_AUTH_SUCCESS:
      return {
        ...state,
        ...{
          token: action.payload.token,
          userInfo: action.payload.user,
          isAuthenticating: false,
          isAuthenticated: true,
          isPageAuthenticated: true,
        },
      };
    case actionTypes.CHECK_AUTH_FAILURE:
      return {
        ...state,
        ...{
          isAuthenticating: false,
          isPageAuthenticated: false,
          isAuthenticated: false,
          token: "",
          user: {},
          userType: null,
        },
      };

    case actionTypes.FETCH_DATA:
      return {
        ...state,
        ...{
          isFetchingData: true,
          isDataFetched: false,
          trackingData: {},
        },
      };
    case actionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        ...{
          isFetchingData: false,
          isDataFetched: true,
          trackingData: action.payload,
        },
      };
    case actionTypes.FETCH_DATA_FAILURE:
      return {
        ...state,
        ...{
          isFetchingData: false,
          trackingData: {},
          isDataFetched: true,
        },
      };

    case actionTypes.SET_FORM_VALUES:
      return {
        ...state,
        ...{
          formValues: action.payload,
        },
      };

    case actionTypes.SUBMIT_TICKET:
      return {
        ...state,
        ...{
          isSubmitting: true,
        },
      };
    case actionTypes.SUBMIT_TICKET_SUCCESS:
      return {
        ...state,
        ...{
          isSubmitting: false,
        },
      };
    case actionTypes.SUBMIT_TICKET_FAILURE:
      return {
        ...state,
        ...{
          isSubmitting: false,
        },
      };

    default:
      return state;
  }
}

export default AuthReducer;
