export const actionTypes = {
  SUCCESS_SNACKBAR: "SUCCESS_SNACKBAR",
  ERROR_SNACKBAR: "ERROR_SNACKBAR",
  CLEAR_SNACKBAR: "CLEAR_SNACKBAR",
};
export function clearAlert(payload) {
  return {
    type: actionTypes.CLEAR_SNACKBAR,
    payload,
  };
}
export function successAlert(payload) {
  return {
    type: actionTypes.SUCCESS_SNACKBAR,
    payload,
  };
}
export function errorAlert(payload) {
  return {
    type: actionTypes.ERROR_SNACKBAR,
    payload,
  };
}
