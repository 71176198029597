import { GoogleApiWrapper } from "google-maps-react";
import { DefaultSeo } from "next-seo";
import dynamic from "next/dynamic";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import Alerts from "../components/Alerts";
import SEO from "../next-seo.config";
import { persistor, store } from "../stores/store";
import "../styles/global.scss";
const PageProgressBar = dynamic(
  () => {
    return import("../components/PageProgressBar");
  },
  { ssr: false }
);

function App({ Component, pageProps }) {
  return (
    <>
      <DefaultSeo {...SEO} />
      <PageProgressBar />
      <ToastContainer />
      <Provider store={store}>
        <Alerts />
        <PersistGate loading={null} persistor={persistor}>
          {() => <Component {...pageProps} />}
        </PersistGate>
      </Provider>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: `${process.env.NEXT_PUBLIC_MAP_KEY}`,
})(App);
