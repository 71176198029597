/**
 *
 * Alerts
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import SuccessSnackbar from './SuccessSnackBar';
import ErrorSnackBar from './ErrorSnackBar';

export function Alerts({ children }) {
  return (
    <>
      <SuccessSnackbar />
      <ErrorSnackBar />
      {children}
    </>
  );
}

Alerts.propTypes = {
  children: PropTypes.node,
};

export default Alerts;
