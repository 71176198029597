import { all } from "redux-saga/effects";
import AuthSaga from "./Auth/saga";

function* rootSaga() {
  yield all([
    AuthSaga(),
  ]);
}

export default rootSaga;
